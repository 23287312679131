.metronome {
  text-align: center;
  max-width: 375px;
  margin: 0 auto;
  padding: 30px;
  background-color: #95e9df;
  border-radius: 20px;
  border: 2px #95e9df solid;
}

.bpm-slide input {
  width: 100%;
  margin: 10px;
}

.metronome button {
  background: #c94d46;
  padding: 10px;
  border: 1px solid #832420;
  width: 100px;
  color: #fff;
  font-size: 18px;
  border-radius: 30px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

